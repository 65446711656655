import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

export const version_timed = "2.0.52" // 02/02/2024

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(
    {
        onUpdate: (registration) => {
            if (registration.waiting) {
                registration.waiting.postMessage({ type: "SKIP_WAITING" });

                // ✅ Correction du typage ici
                registration.waiting.addEventListener("statechange", (event) => {
                    const target = event.target as ServiceWorker; // 🔥 Correction TS
                    if (target.state === "activated") {
                        window.location.reload();
                    }
                });
            }
        },
    }
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
